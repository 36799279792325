module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Via.ork","short_name":"via.work","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"src/assets/images/viawork-favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"21ff7f956766c408a22525d976f1d15e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter:400,500,600,700,800"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-106136032-2","cookieName":"gatsby-gdpr-tracking","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-PH8QKST","cookieName":"via-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
