exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash === "") {
    document.body.scrollTop = 0;
  }
  return false;
};

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location);
function scrollToAnchor(location, mainNavHeight = 18) {
  if (location && location.hash) {
    const item = document.body.querySelector(`${location.hash}`).offsetTop;
    document.body.scrollTo({
      top: item - mainNavHeight,
      behavior: "smooth",
    });
  }

  return true;
}
